.coupon-height {
  height: 50px;
}

.coupon-wrapper {
  background: #ededed;

  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: gray;

  height: 50px;

  font-size: 14px;
}

.coupon-wrapper-text {
  margin-left: 20px;
  width: 90%;

  display: flex;
}

.coupon-btn {
  position: relative !important;
  margin-right: 15px;
  top: -15x;
  background: #ededed;

  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.coupon-name {
  text-decoration: underline;
  font-weight: 600;
  white-space: pre;
}

@media (min-width: 920px) {
  .coupon-wrapper {
    font-size: 18px;
  }

  .coupon-wrapper-text {
    justify-content: center;
    margin-left: 5%;
  }
}
