.privacy-policy-wrapper {
  margin: 10px 6.5%;
  display: flex;
  justify-content: center;
  text-align: center;

  font-size: 12px;
}

.privacy-policy-pdf {
  font-weight: 500;
  color: black;
  text-decoration: underline !important;
}

@media (min-width: 778px) {
  .privacy-policy-wrapper {
    font-size: 12px;
  }
}
